footer {
  display: flex;
  justify-content: center;
  background-color: lightgrey;
  padding: 50px;
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70vw;
    max-width: 350px;
    padding: 0px 35px 20px 20px;
    border-radius: 20px;
    position: relative;
    background-color: #1a1a1a;
    color: #fff;
    border: 1px solid #333;
    #FormTitle {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -1px;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 50px;
      color: #00bfff;
      padding: 30px 50px;
    }  
    #FormTitle::after {
      animation: pulse 1s linear infinite;
    }  
    #FormTitle::before, #FormTitle::after {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: 15px;
      top: 43px;
      background-color: #00bfff;
    } 
    label {
      position: relative;
      .input {
        background-color: #333;
        color: #fff;
        width: 100%;
        padding: 20px 5px 5px 10px;
        outline: 0;
        border: 1px solid rgba(105, 105, 105, 0.397);
        border-radius: 10px;
      }
      span {
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        left: 10px;
        top: 0px;
        font-size: 0.9em;
        cursor: text;
        transition: 0.3s ease;
      }  
      .input:placeholder-shown + span {
        top: 12.5px;
        font-size: 0.9em;
      }  
      .input:focus + span,
      .input:valid + span {
        color: #00bfff;
        top: 0px;
        font-size: 0.7em;
        font-weight: 600;
      }  
      .input {
        font-size: medium;
      }  
    }  
    button {
      margin: auto;
      border: none;
      outline: none;
      padding: 10px;
      border-radius: 10px;
      color: #fff;
      font-size: 16px;
      transition: 0.3s ease;
      background-color: #00bfff;
    }  
    button:hover {
      background-color: #00bfff96;
    }
  }      
  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }  
    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }
}
