.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

textarea {
  resize: none;
}

h2 {
  font-size: 30px;
  padding: 30px;
  margin: 0px;
}

section {
  padding: 30px 0px;
}

img {
  width: 50vw;
}

header {
  background-color: grey;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  gap: 200px;
}

.button {
  border-radius: 25px;
  height: 50px;
  width: 200px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons > .button{
  --color: lightgrey;
  padding: 0.8em 1.75em;
  background-color: transparent;
  border-radius: 25px;
  border: 5px solid var(--color);
  transition: .5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: 'Helvetica', sans-serif;
}

.buttons > .button::after, .buttons > .button::before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: .5s ease-out;
  background-color: var(--color);
}
.buttons > .button::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}
.buttons > .button:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}
.buttons > .button:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}
.buttons > .button:active {
  filter: brightness(.7);
  background-color: grey;
}
.button > img {
  height: 20px;
}
#GitHub_Logo {
  width: 50px;
}
#GitHub_Name {
  width: 20px;
}

.Text {
  text-align: left;
  margin: 0px 200px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1280px) {
  h2 {
    padding: 0px;
    font-size: 24px;
    margin: 0px 30px;
    text-align: left;
  }
  .buttons {
    gap: 10px;
  }
  .buttons > .button {
    padding: 0px;
    width: 100px;
  }
  .Text {
    margin: 0px 20px;
  }
}