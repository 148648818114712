header {
  padding: 70px 0px;
  h1 {
    position: relative;
    top: -40px;
    margin: 0;
    font-size: 50px;
    margin: 20px 80px;
  }
  span {
    position: relative;
    top: -40px;
    font-size: 30px;
    margin: 0px 50px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .gradient-border {
    z-index: 1;
    height: 500px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cercle {
      position: absolute;
      transform: skewX(-50deg);
      height: 500px;
      width: 500px;
      border: 16px solid lightgray;
      border-radius: 50%;
    }
    .front {
      z-index: 1;
      border-style: none solid solid none;
    }
    .back {
      border-style: solid none none solid;
    }
    .planet {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 10px);
      height:  calc(100% - 10px);
      color: white;
      font-family: 'Raleway';
      font-size: 2.5rem;
      background: #1D1F20;
      border-radius: 100%;
    }
  }
  
  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }  
}

@media (max-width: 1280px) {
  header {
    h1 {
      top: -15px;
      font-size: 32px;
      margin: 5px 40px;
    }
    span {
      top: -10px;
      font-size: 26px;
      margin: 0px 30px;
    }
    .gradient-border {
      height: 300px;
      width: 300px;
      .cercle {
        height: 300px;
        width: 300px;
        border: 6px solid lightgray;
      }
      .front {
        border-style: none solid solid none;
      }
      .back {
        border-style: solid none none solid;
      }
    }  
  }
}

@media (max-width: 700px) {
  header {
    .gradient-border {
      .cercle {
        display: none;
      }
    }  
  }
}