.SectionKasa {
  background-color: lightgrey;
  .ImageKasa{
    display: flex;
    justify-content: center;
    gap: 5vw;
    margin: 80px 0px;
    #ImageMobile {
      width: 12vw;
    }
    #ImageDesktop {
      width: 40vw;
    }
  }
  .buttons > .button{
    --color: grey;
  }
}

@media (max-width: 1280px) {
  .SectionKasa {
    .ImageKasa{
      flex-direction: column;
      align-items: center;
      margin: 40px 0px;
      #ImageMobile {
        width: 40vw;
      }
      #ImageDesktop {
        width: 70vw;
      }
    }
  }
}