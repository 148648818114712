.SectionIntroduceDoukro{
  background-color: grey;
  .Card_Images{
    display: flex;
    justify-content: center;
    padding: 0px 150px;
    flex-wrap: wrap;
    gap: 5vw;
    img {
      width: 25vw;
    }
  }
}


@media (max-width: 1280px) {
  .SectionIntroduceDoukro{
    .Card_Images{
      padding: 0px 50px;
      img {
        width: 30vw;
      }
    }
  }
}