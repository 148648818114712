.SectionScreenshot {
  background-color: grey;
  display: flex;
  flex-direction: column;
  .wrapper {
    width: 100%;
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .container {
      height: 500px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      .card {
        width: 80px;
        border-radius: .75rem;
        background-size: cover;
        cursor: pointer;
        overflow: hidden;
        border-radius: 2rem;
        margin: 0 10px;
        display: flex;
        align-items: flex-end;
        transition: .6s cubic-bezier(.28,-0.03,0,.99);
        box-shadow: 0px 10px 30px -5px rgba(17, 14, 14, 0.8);
        img {
          height: 100%;
          width: 800px;
          object-fit: fill;
        }
        .row {
          position: relative;
          height: 100%;
          width: 100%;
          color: white;
          display: flex;
          flex-wrap: nowrap;
          .icon {
            position: absolute;
            bottom: 0px;
            background: #223;
            color: white;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px;
          }
        }
      }    
      input {
        display: none;
      }    
      input:checked + label {
        width: 40vw;
        img {
          width: 40vw;
        }
      }      
      input:checked + label .description {
        opacity: 1 !important;
        transform: translateY(0) !important;
      }  
    }
  }
}

@media (max-width: 1280px) {
  .SectionScreenshot {
    height: 90vh;
    gap: 12vh;
    .wrapper { 
      gap: 15vh;
      .container {
        height: 70vw;
        transform: rotate(90deg);
        .card {       
          img {
            width: 70vw;
            height: 40vw;
            position: relative;
            left: -15vw;
            top: 15vw;
            transform: rotate(-90deg);
          }
          .row {
            position: relative;
            .icon {
              transform: rotate(-90deg);
            }
          }
        }    
        input:checked + label {
          width: 40vw;
          img {
            width: 70vw;
          }
        }
      }
    }
  }
}